import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import DefaultLayoutSidebar from "../../components/DefaultLayoutSidebar"
import CustomNavTitle from "../../components/CustomNavTitle"

import illustration2x from "../../images/illustrations/illustration-administration-documentation@2x.png"

import { theme } from "../../styles/theme"

const Documentation = ({ location }) => {
  return (
    <Layout>
      <SEO title="Documentation and monitoring" />

      <CustomNavTitle
        title="Administration"
        destination="/administration/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h2 css={[theme.components.title, theme.components.titleRed]} className="mt-gap">
            Documentation and monitoring
          </h2>
          <p css={[theme.components.title, theme.components.titleSmall]} className="mt-gap">
            The patient must be kept under close observation throughout the
            transfusion.
          </p>
          <p css={[theme.components.title, theme.components.titleSmall]} className="mt-gap">
            The following must be recorded for every unit transfused:
          </p>
          <ul className="spaced mt-gap-2">
            <li>Date and time commenced and completed.</li>
            <li>Donation number.</li>
            <li>Volume administered.</li>
            <li>
              Observations (check local policy) min requirements P, T, BP and
              RR:
              <br />
              &emsp;- Up to 1 hour before the transfusion commences
              <br />
              &emsp;- 15 minutes after commencement and
              <br />
              &emsp;- within 1 hour of completion.
            </li>
            <li>
              Additional (O₂ saturations, urine output and fluid balance) and increased
              frequency of observations may be required according to the
              patient’s condition e.g. risk of TACO identified.
            </li>
            <li>Any symptoms or complications.</li>
            <li>Fate (traceability) of component (a requirement by law).</li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default Documentation
